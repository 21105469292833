import { type } from '@testing-library/user-event/dist/type';
import React, { Component } from 'react';

type MyProps = {

};

type MyState = {
    choices: any,
    filter: string,
    photos: any
};

export default class Gallery extends Component<MyProps, MyState> {


    constructor(props: MyProps) {
        super(props);

        this.state = {
            choices: ["all", "commercial", "instituition","foundry"],
            filter: "all",
            photos: [
                { name: "Complex", description: "", url: "images/projects/comp1.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp2.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp3.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp4.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp5.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp6.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp8.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp9.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp10.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp14.jpg", categories: ["all", "commercial"] },
                { name: "Complex", description: "", url: "images/projects/comp20.jpg", categories: ["all", "commercial"] },
                { name: "APU", description: "", url: "images/projects/int15.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int16.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int17.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int18.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int19.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int20.jpg", categories: ["all", "instituition"] },
                { name: "APU", description: "", url: "images/projects/int21.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash1.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash2.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash3.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash4.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash5.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash6.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash7.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash8.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash9.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash10.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash11.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash12.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash13.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash14.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash15.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash16.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash17.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash18.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash19.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash20.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash21.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash22.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash23.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash24.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash25.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash26.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash27.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash28.jpg", categories: ["all", "instituition"] },
                { name: "Monash", description: "", url: "images/projects/monash29.jpg", categories: ["all", "instituition"] },
                { name: "Scrap", description: "", url: "images/projects/found1.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found2.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found3.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found4.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found5.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found6.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found7.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found8.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found9.jpg", categories: ["all", "foundry"] },
                { name: "Scrap", description: "", url: "images/projects/found10.jpg", categories: ["all", "foundry"] },
            ]
        }

        this.selFilter = this.selFilter.bind(this);
    }


    selFilter(obj) {

        console.log("Set Filter");
        console.log(obj.target.value);
        this.setState({
            filter: obj.target.value
        })
    }

    render() {
        return (
            <div> 
                {/*<div id="banner-area" className="banner-area" style={{ backgroundImage: "url(images/banner/banner1.jpg)" }}>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-heading">
                                    <h1 className="banner-title">Gallery</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-center">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active"><a href="#">Gallery</a></li>
                                            
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                   
                </div>
                
            </div>*/}
                

                <section id="main-container" className="main-container">
                    <div className="container">
                        <div className="row text-center">
                             <div className="col-lg-12">
                                <h2 className="section-title">Gallery</h2>
                                <h3 className="section-sub-title">Current Projects</h3>
                            </div>
                         </div> 

                        <div className="row">
                            <div className="col-12">
                                <div className="shuffle-btn-group">
                                    {this.state.choices.map((val) => {
                                        return <label htmlFor={val} className={this.state.filter === val ? 'active' : ""}>
                                            <input type="radio" name="shuffle-filter" checked={this.state.filter === val} id={val} value={val} className='gallery-filter' onClick={this.selFilter} />
                                            {val.charAt(0).toUpperCase() + val.slice(1)}
                                        </label>
                                    })}
                                </div>
                                {/* <!-- project filter end --> */}

                                <div className="row shuffle-wrapper">

                                    {
                                        this.state.photos.map((val) => {
                                            return (<div className="col-lg-4 col-md-6 shuffle-item" data-groups={val.categories}
                                                style={{ display: val.categories.indexOf(this.state.filter) > -1 ? "block" : (this.state.filter == "all" ? "block" : "none") }}
                                            >
                                                <div className="project-img-container">
                                                    <a className="gallery-popup" href={val.url}>
                                                        <img className="img-fluid" src={val.url} alt="project-image" />
                                                        <span className="gallery-icon"><i className="fa fa-plus"></i></span>
                                                    </a>
                                                    <div className="project-item-info">
                                                        <div className="project-item-info-content">
                                                            <h3 className="project-item-title">
                                                                <a href="projects-single.html">{val.name}</a>
                                                            </h3>
                                                            <p className="project-cat">{val.categories.map((cat, ind) => {
                                                                return (ind != 0 ? ", " : "") + cat;
                                                            })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>);
                                        })
                                    }

                                </div>
                                {/* <!-- shuffle end --> */}
                            </div>

                            <div className="col-12">
                                {/* <div className="general-btn text-center">
                                    <a className="btn btn-primary" href="projects.html">View All Projects</a>
                                </div> */}
                            </div>

                        </div>
                        {/* <!-- Content row end --> */}

                    </div>
                    {/* <!-- Conatiner end --> */}
                </section>
                {/* <!-- Main container end -->  */}
            </div>
        );
    }
}
