import axios from "axios";

export const URL = "https://subserver.soop.asia/api";
// export const URL = "http://localhost:8000/api";
export var PHONE_NUMBER:string = '(+60)12 971 1691';
export var SINGLE_PHONE:string = '(+60)12 971 1691';
export var ADDRESS1:string = 'H-2-5 Jalan PJU 1A/20E, Dataran Ara Damansara, 47301 Petaling Jaya, Selangor D.E.';
export var EMAIL:string = 'brightnuvamax@gmail.com';
export var COMPANY_NAME:string = 'Nuvamax';
export var COMPANY_PREFIX:string = 'Nuvamax Bright Trading';
export const MAP_KEY:string = "AIzaSyBI6OAZdPybfmIGfchHQ1Ghs9tMyhx-qHc";
export var lat:number = 3.12057;
export var lng:number = 101.69197;
axios.defaults.baseURL = URL;

